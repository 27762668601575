<template>
  <div class="agreement">
    <div class="header">
      <img src="@/assets/img_01.png" alt="" class="logo" />
    </div>
    <h3 class="title">和和数据传输平台用户协议</h3>
    <p class="time">更新日期：2022年7月1日</p>
    <div class="desc">
      欢迎您使用和和数据传输平台的服务！为更好地使用和和数据传输平台的服务，您应当阅读并遵守《和和数据传输平台用户协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任、排除或限制用户权利的条款，以及开通或使用某项服务的单独协议。限制、免责条款以加粗的形式提示您重点注意。当您点击“同意”本协议，注册成为和和数据传输平台CRM产品及其增值服务（以下简称“和和数据传输平台CRM”）用户，即视为您已阅读并同意接受本协议的约束。如您不同意本协议，请立即停止注册或使用和和数据传输平台CRM的服务。
      一、总则
      1.本协议是上海和和义齿有限公司（以下简称“和和数据传输平台”或“我们”）与您（以下或称“用户”）签订的协议。
      2.和和数据传输平台CRM服务主要是方便上海和和义齿有限公司与用户之间的数据传输使用。
      3.如本协议与您和和和数据传输平台签订的线下协议或购买具体产品生成的电子订单的规定不一致，则以线下协议或电子订单的规定为准。
      二、账户注册
      1.您在使用和和数据传输平台CRM时需要注册一个账号，或使用我们认可的第三方进行登录，否则我们有权拒绝向您提供服务。
      2.账户注册条件：
      （1）和和数据传输平台CRM仅向具有民事权利能力并能够独立承担民事责任的自然人、法人或其他组织提供服务。
      （2）如您不具备该等资格，请您立即停止注册或使用和和数据传输平台CRM的服务，否则和和数据传输平台有权随时暂停或终止您的用户资格。
      3.完成注册：
      （1）您应当按照注册页面的提示，提供真实、完整、准确的信息，您不得使用他人的信息注册账号或向和和数据传输平台提供其他的虚假信息。
      （2）和和数据传输平台CRM账号的所有权归和和数据传输平台所有，您完成申请注册后，仅获得账号的使用权。
      4.信息真实性保证：
      （1）您在注册账号或使用本服务的过程中，需要填写一些必要的信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。
      （2）您应及时更正不实信息，更新、完善您的相关信息。如发现您以虚假信息骗取注册，和和数据传输平台有权不经通知单方采取限期改正、删除资料、暂停使用、注销、收回账户并终止对您提供部分或全部服务等措施。
      （3）如您未及时更正、更新信息导致和和数据传输平台无法按协议约定向您提供服务，或导致您在使用和和数据传输平台CRM产品或相关服务过程中产生任何损失或费用的，由此产生的后果由您自行承担。
      （4）和和数据传输平台对以上原因造成的服务暂停、终止不承担任何责任，您应当承担一切不利后果。如因此给和和数据传输平台造成损失的，您应当承担相应的责任。
      三、账户安全
      1.和和数据传输平台特别提醒您应妥善保管您的账号、密码及其他有效识别信息。当您使用完毕后，应安全退出。因您保管不善可能导致账号、密码或其他有效识别信息泄露、丢失或账号和密码被窃取、篡改等导致的一切损失，责任由您自行承担。
      2.如您发现账号、密码被他人冒用或盗用，请立即以有效方式通知和和数据传输平台，要求和和数据传输平台暂停服务。和和数据传输平台在采取行动的合理期限内，不对您的损失承担任何责任。
      3.除本协议另有约定，您不应将账号、密码以及账户转让、出售或出借予他人使用。通过您的账户编辑、发布的任何信息或作出的任何行为都将视为您本人的行为。若您授权他人使用账户，应对被授权人在该账户下发生所有行为负全部责任。
      四、账户使用规范
      1.您理解并同意，您不得利用和和数据传输平台CRM产品、服务制作、复制、发布、传播如下内容：
      （1）反对中华人民共和国宪法所确定的基本原则的；
      （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      （3）损害国家荣誉和利益的； （4）煽动民族仇恨、民族歧视，破坏民族团结的；
      （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
      （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
      （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      （8）侮辱或者诽谤他人，侵害他人合法权益的；
      （9）侵犯他人的肖像权、隐私权、商业秘密或其他任何合法权益的；
      （10）含有法律、行政法规禁止的其他内容的。
      2.您在使用和和数据传输平台的产品或服务时，必须遵守中华人民共和国相关法律法规及本协议的规定，不得利用和和数据传输平台的服务从事任何违法违规或可能违反本协议的活动。如您违反相关法律法规或本协议规定，使用和和数据传输平台的服务造成对第三方合法权益的损害，对第三方的损失，您应当承担全部的责任，和和数据传输平台不为此承担任何责任。
      3.和和数据传输平台有权基于单方独立判断，在认为可能发生危害国家安全、公共安全、社会公共利益、和和数据传输平台或其他个人合法权益等情形时，不经通知而先行限制或关闭用户的账户，暂停或终止向用户提供本协议项下的全部或部分服务，并将用户的相关数据删除，且无需对用户承担任何责任。前述情形包括但不限于：
      （1）和和数据传输平台认为您提供的信息不具有真实性、完整性或准确性，包括但不限于盗用他人证件信息注册等；
      （2）和和数据传输平台发现您未经和和数据传输平台同意，转让、出售或出借予他人使用该和和数据传输平台CRM账户；
      （3）和和数据传输平台发现您利用和和数据传输平台的服务从事违反法律的活动；
      （4）和和数据传输平台认为您已经违反本协议及相关协议和规则中规定的条款；
      （5）法律规定或有权机关的要求。
      4.如您违反本协议或相关的服务条款的规定，导致或产生的任何和和数据传输平台或第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿和和数据传输平台、合作机构，并使之免受损害。对此，和和数据传输平台有权视您的行为性质，采取包括但不限于暂停使用、限制使用、终止服务、回收账户、追究法律责任等措施。
      五、账户注销
      1.如您的账户下的行为严重违反本协议中的账户使用规范或基于国家有权的机关的要求，和和数据传输平台有权注销您的账户。
      2.如您向和和数据传输平台提出账户注销申请，当您的账户符合以下条件，经和和数据传输平台审核通过即可注销账户：
      （1）账户处于正常状态，不涉及任何争议纠纷（包括投诉举报或被投诉举报），且未被有权机关采取限制措施；
      （2）账户内无未完成的交易；
      （3）账户内不存在其他未了结的权利义务或因注销账户而产生纠纷的情况；
      （4）其他可能影响账户注销的情况。
      3.如您需要注销账户的，应按照和和数据传输平台要求的程序提出注销申请，经过和和数据传输平台审核通过后，予以注销。自和和数据传输平台通过用户预留的邮箱、短信等方式通知您之日起，账户注销生效。
      4.在账户注销过程中，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，和和数据传输平台有权中止或终止您账户的注销程序而无需另行得到您的同意。
      5.您已充分理解并同意，账户注销后，您将无法再继续使用和和数据传输平台提供的服务，不再拥有账户相关的权益。法定期限届满后，账户相关的内容或数据将被删除或匿名化，且无法恢复。
      6.因注销账户所带来的不便或不利后果，由您自行承担。 六、个人信息安全
      1.您注册的和和数据传输平台CRM账户具有密码保护功能，以确保您个人信息的安全，请您妥善保管账户及密码信息。一旦忘记密码，通过非客户端的管理后台找回密码即可重新设置。
      2.和和数据传输平台重视对用户个人信息的保护。《和和数据传输平台个人信息保护政策》呈现了和和数据传输平台将如何收集、使用、保存、共享及保护您的个人信息，详情请参阅《和和数据传输平台个人信息保护政策》。
      3.您理解并同意，您通过和和数据传输平台提供的产品或服务自行上传的数据均为您的客户数据。您应对您的客户数据来源及内容的合法性与正当性负责，如您上传客户数据的行为违反法律，侵犯他人的肖像权、隐私权、个人信息、商业秘密或其他任何合法权益，给国家、社会或他人造成损害，您应自行承担全部责任。
      4.由于和和数据传输平台CRM同时会为您提供数据分析服务，数据分析内容是基于您上传的客户数据而形成的大数据产品，您仅能将数据分析结果用于自行营销参考，不可用于其他目的，也不得提供给任何第三方。
      5.就您的客户数据，我们除执行您在使用和和数据传输平台的产品或服务过程中发出的指令之外，不进行任何未经授权的收集、保存、使用及对外提供，但以下情形除外：
      （1）遵守法律法规的要求；
      （2）配合国家有权机关依法查询、调阅您的客户数据；
      （3）为了解决您与我们之间的争议或第三方因您的行为与我们发生的争议；
      （4）为了履行您与我们之间的协议； （5）为了向您提供产品或服务；
      （6）为了改善、提升产品或服务的质量；
      （7）您与我们另行协商一致的其他情形。
      6.您理解并同意，为了向您提供用户画像及其他数据分析等服务，和和数据传输平台将在获得您的授权后为您形成分析结果，供您营销参考。
      七、风险自担
      1.和和数据传输平台将按现有技术水平，尽力促使和和数据传输平台服务的正确、持续、及时和安全，但在法律法规允许的最大范围内，不就此负担任何明示或默示的保证责任。您同意承担使用和和数据传输平台服务的所有风险及因该风险可能造成的任何损害，和和数据传输平台对于您所使用的各项服务或无法使用各项服务所导致的任何直接、间接、衍生或特别的损害，包括但不限于商业利润的损失、信息或数据的丢失，都不负任何赔偿责任，即使和和数据传输平台已被告知存在这种损害的可能性也不例外。若因服务器、通信网络和移动智能终端的软硬件故障、电信部门技术调整、计算机病毒、恶意攻击及其他不可预测的因素而受到影响，造成您无法正常使用和和数据传输平台CRM，和和数据传输平台不承担任何责任。尽管有前述约定，我们将采取合理行动促使服务恢复正常。
      2.在适用法律允许的范围内，和和数据传输平台有权随时自行决定暂停、终止、修改服务，或对部分用户暂停、终止、修改服务，且毋须另行通知。
      3.和和数据传输平台CRM会含有到其他网站的链接。和和数据传输平台提供该等链接的目的仅在于向您提供方便，和和数据传输平台对其他网站的隐私保护措施不负任何责任，亦不代表和和数据传输平台对该等第三方、其产品及服务的任何推荐、支持或授权，您应当自行甄别并自担风险。您在使用上述第三方的任何产品和服务时，应当了解并接受该等第三方的条款和规则。
      4.和和数据传输平台CRM为帮助您管理客户、获取客户线索的工具，您作为独立商业主体，应审慎思考、综合判断后再作出商业决策，如因和和数据传输平台CRM产品为您提供的参考策略不精确而引发错失商业机会等损失，和和数据传输平台不为此承担责任。
      5.因您自身的原因导致的任何损失或责任，由您自行负责，和和数据传输平台不承担责任。和和数据传输平台不承担责任的情形包括但不限于：
      （1）您未按照本协议或和和数据传输平台不时公布的任何规则进行操作导致的任何损失或责任；
      （2）您未向和和数据传输平台提供正确的联络方式，未能及时收到和和数据传输平台相关通知导致的任何损失或责任；
      （3）其他因您自身原因导致的任何损失或责任。 八、知识产权的保护
      1.和和数据传输平台CRM及和和数据传输平台向您提供服务过程中使用的所有内容，包括但不限于任何文字、图像、视频、图表、软件及其他材料均为和和数据传输平台或其他特别注明的权利人所有。和和数据传输平台及相应权利人并未通过本协议授予您对上述信息和内容的任何其他权利。除非经相应权利人事先书面同意，您不得以任何形式向任何第三方公开发表、传输、复制或以其他方式使用和和数据传输平台提供的全部或部分信息，不得更改、虚化或删除任何署名、商标、版权标识和/或其他权利声明。
      2.尊重知识产权是您应尽的义务，如您有违反，造成和和数据传输平台或相应权利人损失的，您需要承担损害赔偿责任。
      九、商业活动
      1.和和数据传输平台CRM中包含第三方产品或服务的网站链接，我们只向该第三方提供和和数据传输平台CRM用户的群体信息，我们将不会向第三方提供您的个人信息。
      2.您理解并同意，我们可以在和和数据传输平台提供的服务中进行下述商业活动：通过和和数据传输平台CRM、手机短信、邮件、电话或其他合法方式向您发送商业性广告或其他相关商业信息。
      十、本协议的更新和补充
      1.我们可能会适时对本协议进行修订。当本协议条款发生变更时，我们会在您登录及版本更新时以推送通知、弹窗的形式向你展示变更后的协议。该等变更或调整用户协议内容的行为不视为和和数据传输平台违反本协议之约定。如您在本协议修改后继续使用我们提供的产品或服务的，即表示您已充分阅读、理解并同意修改后的协议，愿意受修改后协议的约束；如您不同意修改后的协议，请立即停止使用和和数据传输平台CRM。
      2.除本协议以外，我们已经发布的或将来可能发布的有关和和数据传输平台向您提供产品或服务的协议、服务规则、政策、须知等内容，均视为本协议的补充协议，作为本协议不可分割的组成部分，与本协议具有同等法律效力。请您及时关注并阅读前述相关补充协议。相关补充协议的部分或全部条款被认定无效的，不影响其它协议或协议条款的效力。
      十一、其他
      1.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效且具有约束力，您和和和数据传输平台均应尽力使该条款设定的本意得到实现。
      2.本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。
      3.本协议签订地为中国上海市宝山区。因本协议所引起的用户与和和数据传输平台的任何纠纷或争议，双方应尽量友好协商解决；协商不成的，任何一方均可向和和数据传输平台所在地即上海市宝山区有管辖权的人民法院提起诉讼。
    </div>
  </div>
</template>

<script>
export default {
  name: "agreement",
  data() {
    return {
      form: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  min-height: 100vh;
  background: #f5f7f9;
  padding-top: 100px;
  min-width: 1200px;
  .header {
    height: 100px;
    display: flex;
    align-items: center;
    background: #2D8CF0;
    padding-left: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .logo {
      margin-right: 15px;
    }
  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    line-height: 1.5;
    padding-top: 30px;
  }
  .time {
    text-align: end;
    line-height: 1.5;
    font-size: 14px;
    color: #000;
    width: 1200px;
    margin: 0 auto;
  }
  .desc {
    width: 1200px;
    margin: 0 auto;
    padding: 10px 0 80px;
    line-height: 1.5;
    font-size: 14px;
    color: #000;
    white-space: pre-wrap;
  }
}
</style>